import React from 'react';
const HIPAALogo: React.FC = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="93" height="31" viewBox="0 0 93 31" fill="none" data-sentry-element="svg" data-sentry-component="HIPAALogo" data-sentry-source-file="HIPAALogo.tsx">
      <g clipPath="url(#clip0_1_3857)" data-sentry-element="g" data-sentry-source-file="HIPAALogo.tsx">
        <path d="M83.1032 22.8579C83.0422 22.9287 83.016 22.973 82.9463 23.0438L83.0335 23.1322C83.1277 23.2404 83.1945 23.3703 83.2279 23.5107C83.2613 23.6511 83.2604 23.7976 83.2252 23.9376C83.2252 23.9376 83.3124 23.8933 83.3647 23.7252C83.3894 23.6006 83.3874 23.4721 83.3588 23.3484C83.3302 23.2248 83.2757 23.1088 83.1991 23.0084L83.1032 22.8579Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M83.5564 19.4693C83.3908 19.5843 83.2165 19.6905 83.0421 19.7879C83.2132 19.8721 83.3791 19.9667 83.539 20.0711C83.539 20.0711 83.3908 20.3897 83.199 20.5224C83.0073 20.6551 82.7806 20.8498 82.5801 21.0091C82.3564 21.1729 82.1742 21.3882 82.0484 21.6374C81.9959 21.7744 81.9897 21.9252 82.0307 22.0661C82.0717 22.2071 82.1576 22.3302 82.275 22.4162L82.5888 22.7259C82.5888 22.6817 82.7196 22.602 82.7632 22.5135C82.336 22.1596 82.0745 21.7259 82.8155 21.3277C83.016 21.1861 83.2426 21.0445 83.4693 20.8587C83.5991 20.7686 83.7138 20.6581 83.8092 20.5312C83.8933 20.4574 83.9545 20.3605 83.9854 20.2521C84.0163 20.1438 84.0157 20.0286 83.9836 19.9206C83.95 19.8182 83.8953 19.7242 83.8231 19.6448C83.751 19.5655 83.6631 19.5026 83.5652 19.4604" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M84.1836 16.0798C84.1836 16.0798 83.495 16.3542 83.216 16.4958L83.6257 16.6108C83.7007 16.6341 83.7736 16.6637 83.8436 16.6993L83.7826 16.7612C83.679 16.8515 83.5611 16.9233 83.4339 16.9736L82.4402 17.425C82.1264 17.6197 81.8126 17.6374 81.4813 18.1064C81.4127 18.2315 81.3858 18.3759 81.4047 18.5178C81.4235 18.6598 81.4871 18.7917 81.5859 18.894C81.7113 19.0401 81.8584 19.1655 82.0218 19.2657L82.1787 19.363C82.3501 19.2471 82.5276 19.1407 82.7104 19.0444L82.3269 18.8055C82.0654 18.6462 81.8997 18.3807 81.9782 18.3984C82.1976 18.224 82.4415 18.0838 82.7017 17.9825L83.6431 17.5666C83.8407 17.4943 84.0238 17.3863 84.1836 17.248C84.2829 17.1874 84.365 17.1018 84.4221 16.9994C84.4791 16.8971 84.5091 16.7815 84.5091 16.6639C84.5091 16.5463 84.4791 16.4307 84.4221 16.3284C84.365 16.226 84.2829 16.1404 84.1836 16.0798Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M84.7154 12.6089C84.5323 12.5293 84.3492 12.4496 84.1749 12.3877C83.8139 12.5885 83.4379 12.7601 83.0504 12.901C83.2422 12.9806 83.4427 13.0603 83.6606 13.1311C83.895 13.2059 84.125 13.2945 84.3492 13.3965L84.5062 13.4762C84.428 13.512 84.3461 13.5387 84.2621 13.5558C83.9762 13.6387 83.6849 13.7008 83.3904 13.7417C82.6914 13.7815 82.0043 13.9432 81.3593 14.2196C81.1346 14.3216 80.9438 14.4876 80.8101 14.6974C80.7608 14.782 80.7299 14.8763 80.7194 14.9739C80.7088 15.0716 80.719 15.1704 80.7491 15.2638C80.8032 15.4037 80.89 15.5282 81.0019 15.6266C81.1752 15.7547 81.3661 15.8561 81.5685 15.9275C81.7648 15.8699 81.957 15.799 82.1438 15.7151L82.2746 15.662L82.5971 15.5293L82.3356 15.4408C82.0533 15.3511 81.788 15.2135 81.5511 15.0337C81.6156 14.9816 81.6858 14.937 81.7603 14.901C82.3404 14.6873 82.9485 14.5621 83.5647 14.5293C83.8903 14.4853 84.2134 14.4232 84.5323 14.3434L85.0117 14.1842C85.1476 14.1387 85.2703 14.0601 85.3691 13.9551C85.4679 13.8502 85.5398 13.7222 85.5784 13.5824C85.744 13.0603 84.8984 12.6532 84.7677 12.5824" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M83.3121 21.1233C83.222 21.2126 83.1259 21.2954 83.0244 21.3711C84.0269 21.964 82.7891 22.495 82.5537 23.0082C82.4785 23.1094 82.4256 23.2257 82.3986 23.3494C82.3715 23.473 82.3709 23.6011 82.3968 23.7251C82.4491 23.8932 82.5276 23.9463 82.5363 23.9374C82.4943 23.7981 82.49 23.6498 82.5237 23.5082C82.5575 23.3665 82.628 23.2367 82.7281 23.1321C82.9643 22.8726 83.2209 22.6329 83.4952 22.4153C83.6061 22.324 83.6871 22.2007 83.7276 22.0617C83.768 21.9226 83.766 21.7744 83.7218 21.6366C83.6297 21.4334 83.4888 21.2569 83.3121 21.1233Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M83.7305 17.6453C83.7305 17.6453 83.2597 17.9019 83.0331 18.0081C83.279 18.0996 83.5087 18.2311 83.713 18.3975C83.8002 18.3975 83.6259 18.6453 83.3731 18.8046C83.1203 18.9638 82.8413 19.1143 82.5798 19.2647C82.3183 19.4152 81.9347 19.4152 81.7081 19.9196C81.6726 20.0266 81.6695 20.1418 81.699 20.2506C81.7285 20.3594 81.7893 20.4568 81.8737 20.5302C81.9735 20.653 82.0877 20.763 82.2137 20.8577L82.3444 20.955L82.7367 20.7161L82.4926 20.5214C82.3009 20.3886 82.1091 20.0966 82.1527 20.0789C82.3516 19.9455 82.5616 19.83 82.7803 19.7338C83.0505 19.5833 83.3382 19.4506 83.652 19.2647C83.8166 19.1626 83.9663 19.0375 84.0966 18.8931C84.1954 18.7908 84.259 18.6589 84.2778 18.5169C84.2967 18.3749 84.2698 18.2306 84.2012 18.1054C84.0764 17.9152 83.9092 17.7575 83.713 17.6453" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M84.6544 14.4158C84.4365 14.4955 83.5648 14.6813 83.3643 14.7167C83.5859 14.7539 83.8005 14.8256 84.0006 14.9291C84.0657 14.9603 84.1271 14.9989 84.1837 15.0442C83.9441 15.2248 83.6758 15.3625 83.3904 15.4512C83.0853 15.5663 82.7454 15.6548 82.4054 15.761L81.8911 15.9291C81.7429 15.9999 81.464 15.9291 81.2635 16.3539C81.1873 16.5047 81.1691 16.6789 81.2127 16.8425C81.2562 17.0062 81.3582 17.1475 81.4988 17.2388C81.6252 17.3538 81.7695 17.4465 81.926 17.5132L82.6669 17.2123L82.7366 17.168L82.3008 16.9645C82.1721 16.9117 82.0517 16.8401 81.9434 16.7521L81.8911 16.6902C81.9605 16.653 82.0335 16.6233 82.109 16.6017L82.6059 16.4601C82.9284 16.3627 83.2771 16.2919 83.6258 16.168C84.0115 16.0557 84.3725 15.8694 84.6893 15.6194C84.8012 15.521 84.8879 15.3965 84.9421 15.2565C84.9722 15.1632 84.9823 15.0643 84.9718 14.9667C84.9613 14.869 84.9303 14.7747 84.881 14.6902C84.8131 14.5846 84.731 14.4893 84.637 14.407" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M85.3521 9.1125C85.1273 8.98582 84.8768 8.91316 84.6199 8.90012C84.4456 8.90012 83.9836 8.74967 83.8266 8.90012C83.6697 9.05056 83.8266 9.42224 83.9574 9.53728C84.3245 9.83053 84.7452 10.0471 85.1952 10.1745C85.3521 10.2275 85.7618 10.4576 85.4306 10.6877C85.1168 10.8887 84.7874 11.0633 84.4456 11.2098C84.2102 11.316 83.7046 11.5461 82.9811 11.847C82.6237 11.9975 82.214 12.1745 81.7607 12.3426C81.5027 12.4287 81.2495 12.5292 81.0023 12.6435C80.8716 12.7143 80.026 13.1214 80.1829 13.6435C80.2223 13.7843 80.2955 13.9129 80.3959 14.0179C80.4963 14.123 80.6208 14.2011 80.7582 14.2452L81.0633 14.3514C81.0814 14.3286 81.1019 14.3079 81.1244 14.2895C81.4089 14.0568 81.7262 13.8688 82.0658 13.732C81.8594 13.6994 81.6555 13.6521 81.4556 13.5904C81.3687 13.5732 81.284 13.5465 81.2028 13.5107L81.3684 13.4311C81.5903 13.3305 81.8173 13.2419 82.0484 13.1656C82.5278 12.9975 82.9201 12.8293 83.3211 12.6789C84.0533 12.378 84.5763 12.1479 84.8204 12.0329C85.347 11.831 85.8232 11.5137 86.2151 11.1037C86.3227 10.9624 86.3929 10.7955 86.4188 10.6188C86.4448 10.4422 86.4256 10.2618 86.3633 10.0948C86.1581 9.65128 85.7976 9.30113 85.3521 9.1125Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.6584 1.76761C82.4477 1.76586 82.2412 1.82769 82.0652 1.94525C81.8892 2.06281 81.7515 2.23081 81.6697 2.42793C81.5878 2.62505 81.5655 2.8424 81.6056 3.05241C81.6456 3.26242 81.7462 3.45562 81.8946 3.6075C82.043 3.75938 82.2324 3.86309 82.439 3.90547C82.6455 3.94785 82.8598 3.92699 83.0546 3.84554C83.2494 3.76409 83.416 3.62572 83.5333 3.44799C83.6505 3.27026 83.7131 3.06117 83.7131 2.84726C83.7132 2.56245 83.6023 2.28917 83.4047 2.08695C83.2072 1.88473 82.9389 1.76995 82.6584 1.76761Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M83.0159 4.0769H82.3011C82.2641 4.0769 82.2286 4.09182 82.2025 4.11838C82.1763 4.14493 82.1616 4.18094 82.1616 4.2185C82.1616 4.25691 82.176 4.29389 82.2019 4.32187C82.2278 4.34984 82.2633 4.36668 82.3011 4.36894H83.0159C83.0537 4.36668 83.0892 4.34984 83.1151 4.32187C83.141 4.29389 83.1554 4.25691 83.1554 4.2185C83.1554 4.18094 83.1407 4.14493 83.1145 4.11838C83.0884 4.09182 83.0529 4.0769 83.0159 4.0769Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.6583 4.51855C82.5276 4.51855 82.423 4.5628 82.423 4.75749V11.8814L82.667 11.7486H82.7804V4.81944C82.7804 4.62475 82.6758 4.5805 82.545 4.5805" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.8853 23.1479L82.7371 23.2276V24.6081C82.745 24.6407 82.7635 24.6697 82.7896 24.6904C82.8157 24.7111 82.8478 24.7223 82.8809 24.7223C82.914 24.7223 82.9461 24.7111 82.9722 24.6904C82.9983 24.6697 83.0168 24.6407 83.0247 24.6081V23.1922H82.9463H82.8765" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.6496 15.6451C82.7663 15.5976 82.8902 15.5707 83.0158 15.5654V14.8132C82.8882 14.8264 82.7597 14.8264 82.6322 14.8132L82.6496 15.6451Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.8938 19.9094L82.6759 19.9979V20.7059C82.7423 20.6665 82.8124 20.6339 82.8851 20.6085V19.874H82.8241" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.6846 21.7152V22.4409V22.4851C82.7171 22.4617 82.7541 22.4457 82.7932 22.4381C82.8323 22.4304 82.8725 22.4314 82.9112 22.4409V21.5559H82.7892L82.5974 21.724" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.9723 18.1954C82.9294 18.1772 82.8908 18.15 82.8589 18.1157L82.6584 18.1777V19.0095L82.7805 19.0803H82.981V18.2573L82.9723 18.1954Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.6584 17.1589L82.7107 17.2297L82.9635 17.15V16.5217L82.5974 16.6279L82.6584 17.1589Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M82.8327 13.1665H82.7107H82.6322V13.8479L82.9547 13.7594H83.0245V13.1311C82.9629 13.1534 82.8981 13.1654 82.8327 13.1665Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M81.1588 6.06863C80.7404 5.85624 81.1588 5.55536 81.2634 5.28987C81.4693 4.979 81.5758 4.61129 81.5685 4.23677C81.501 4.02856 81.3767 3.84405 81.21 3.70465C81.0434 3.56524 80.8413 3.47666 80.627 3.44916C80.4202 3.42365 80.2104 3.44193 80.0108 3.50286C79.8113 3.56378 79.6263 3.66603 79.4677 3.80315C79.0935 4.07362 78.6899 4.29931 78.2647 4.47571C76.534 5.29271 74.7097 5.88727 72.834 6.24562C73.0208 6.49733 73.2549 6.70902 73.5227 6.86849C73.7905 7.02797 74.0867 7.13208 74.3943 7.17483C74.3943 7.46686 74.9174 7.7766 75.144 7.85624C75.4646 7.96289 75.8128 7.94395 76.1203 7.80315C76.2119 7.94316 76.3306 8.06279 76.4692 8.15475C76.6078 8.2467 76.7634 8.30907 76.9265 8.33804C77.0896 8.36702 77.2568 8.362 77.4179 8.32329C77.5791 8.28458 77.7307 8.21299 77.8637 8.11288C77.8637 8.38722 78.3693 8.52881 78.5785 8.58191C78.907 8.62696 79.2413 8.5811 79.5461 8.44916C79.7728 8.39607 79.8687 8.54651 80.1127 8.59961C80.3671 8.6728 80.6397 8.64099 80.8711 8.51111C80.9844 8.45801 80.9409 8.42262 81.0629 8.43147C81.1849 8.44031 81.246 8.51996 81.3331 8.53766C81.5685 8.53766 81.7341 8.38722 81.8649 8.21908C82.0362 7.94076 82.1766 7.64395 82.2833 7.33412V6.12173C81.9257 6.17043 81.5623 6.15244 81.2111 6.06863" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M85.857 3.80385C85.7005 3.66602 85.5171 3.56326 85.3189 3.50226C85.1206 3.44127 84.9119 3.42341 84.7063 3.44986C84.4921 3.47736 84.29 3.56594 84.1233 3.70535C83.9567 3.84475 83.8324 4.02926 83.7649 4.23747C83.741 4.61326 83.8324 4.98733 84.0264 5.30827C84.1746 5.57376 84.5494 5.87464 84.131 6.08703C83.7881 6.16707 83.434 6.18505 83.085 6.14013V7.38792C83.1803 7.6795 83.309 7.9587 83.4685 8.21978C83.5993 8.38792 83.7649 8.57376 84.0003 8.53836C84.0874 8.53836 84.2007 8.43216 84.2705 8.43216C84.3402 8.43216 84.3489 8.43216 84.4623 8.51181C84.6937 8.64169 84.9662 8.6735 85.2206 8.60031C85.4647 8.54721 85.5606 8.39677 85.7873 8.44986C86.1053 8.58997 86.4558 8.63598 86.7984 8.58261C87.0076 8.52951 87.4871 8.38792 87.5132 8.11358C87.6462 8.21369 87.7979 8.28528 87.959 8.32399C88.1202 8.3627 88.2874 8.36772 88.4505 8.33874C88.6135 8.30977 88.7691 8.2474 88.9077 8.15545C89.0463 8.06349 89.165 7.94386 89.2567 7.80385C89.5639 7.94551 89.9125 7.96446 90.233 7.85694C90.4596 7.7773 91.0088 7.46756 90.9826 7.17553C91.2902 7.13278 91.5865 7.02867 91.8543 6.86919C92.1221 6.70972 92.3561 6.49803 92.543 6.24632C90.6673 5.88797 88.8429 5.29341 87.1123 4.47641C86.6852 4.29815 86.2789 4.0726 85.9006 3.80385" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M80.069 10.7617C79.7465 10.5316 80.1475 10.3015 80.3044 10.2484C80.761 10.1176 81.1897 9.90154 81.5683 9.61126C81.6991 9.48736 81.9083 9.12453 81.6904 8.97409C81.4725 8.82365 81.0802 8.97409 80.9058 8.97409C80.6475 8.98828 80.395 9.05764 80.1649 9.17763C79.729 9.36773 79.3716 9.70554 79.1537 10.1334C79.0961 10.3012 79.0795 10.4806 79.1053 10.6563C79.1311 10.8321 79.1986 10.9988 79.3019 11.1422C79.7 11.5384 80.1792 11.8405 80.7054 12.0272L81.333 12.3104C81.3766 12.2661 82.135 11.9918 82.4226 11.7971L81.0715 11.2042C80.7297 11.0577 80.4002 10.8831 80.0864 10.6821" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M37.2603 14.6792H34.7236V10.8208H31.7947V14.6792H29.2667V5.21021H31.7947V8.75003H34.7236V5.21021H37.2603V14.6792Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M40.1101 14.6792V5.21021H42.6381V14.6792H40.1101Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M52.2617 8.21853C52.2903 8.67054 52.2226 9.1235 52.0631 9.54653C51.9037 9.96956 51.6562 10.3527 51.3377 10.6699C50.6115 11.297 49.6742 11.6142 48.7226 11.5548H48.0078V14.6964H45.4885V5.20968H48.7138C49.649 5.14599 50.5759 5.42203 51.329 5.98844C51.6392 6.26537 51.8841 6.60959 52.0456 6.99562C52.207 7.38164 52.2809 7.7996 52.2617 8.21853ZM48.0078 9.44862H48.4436C48.763 9.45566 49.0736 9.34214 49.3153 9.13003C49.4266 9.01292 49.5133 8.87411 49.5703 8.72201C49.6272 8.5699 49.6532 8.40766 49.6466 8.24508C49.6466 7.59906 49.2979 7.28048 48.6005 7.28048H48.0078V9.44862Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M59.7763 14.6783L59.3142 12.9084H56.2894L55.8187 14.6783H53.0205L56.0628 5.16504H59.4276L62.5134 14.6783H59.7763ZM58.7912 10.8022L58.3902 9.25354C58.2943 8.9084 58.181 8.45708 58.0503 7.9084C57.9195 7.35973 57.8236 6.97035 57.78 6.73141C57.78 6.95265 57.6667 7.31548 57.5534 7.81991C57.4401 8.32433 57.196 9.31548 56.8037 10.8022H58.7912Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M70.7334 14.6783L70.2714 12.9084H67.2465L66.7671 14.6783H64.0038L67.046 5.16504H70.4108L73.4967 14.6783H70.7334ZM69.7483 10.8022L69.3648 9.25354C69.2689 8.9084 69.1556 8.45708 69.0248 7.9084C68.8941 7.35973 68.8069 6.97035 68.7633 6.73141C68.7197 6.95265 68.65 7.31548 68.5367 7.81991C68.4233 8.32433 68.1705 9.31548 67.787 10.8022H69.7483Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M31.4193 18.9359C31.1465 18.9217 30.8739 18.97 30.6219 19.0773C30.3699 19.1845 30.1449 19.3479 29.9636 19.5554C29.5894 20.0312 29.3974 20.6285 29.4231 21.2368C29.3826 21.8577 29.5688 22.4721 29.9461 22.9625C30.1289 23.17 30.3556 23.3328 30.6093 23.4386C30.8629 23.5444 31.1369 23.5903 31.4106 23.5731C31.8643 23.5733 32.316 23.5108 32.753 23.3872V23.9625C32.2937 24.1235 31.8092 24.1985 31.3234 24.1837C30.9697 24.2075 30.6151 24.1516 30.285 24.02C29.955 23.8884 29.6577 23.6845 29.4144 23.4226C28.9184 22.81 28.672 22.0279 28.7258 21.2368C28.7138 20.7041 28.8274 20.1761 29.057 19.697C29.2566 19.2656 29.5823 18.9071 29.9897 18.6704C30.4298 18.4251 30.9262 18.303 31.428 18.3164C31.9602 18.3046 32.488 18.4166 32.971 18.6439L32.692 19.2102C32.2932 19.0237 31.8584 18.9299 31.4193 18.9359Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M39.2212 21.2377C39.2637 22.0124 39.0214 22.7755 38.5413 23.3793C38.3027 23.6434 38.0091 23.8503 37.6819 23.985C37.3548 24.1197 37.0022 24.1788 36.6497 24.1581C36.2963 24.1782 35.9427 24.1207 35.6133 23.9893C35.2838 23.8579 34.9861 23.6559 34.7407 23.397C34.264 22.785 34.0252 22.0166 34.0694 21.2377C34.0209 20.4568 34.2636 19.6859 34.7494 19.0784C34.9938 18.8181 35.2913 18.6151 35.621 18.4836C35.9508 18.3521 36.3048 18.2954 36.6584 18.3173C37.0109 18.2966 37.3635 18.3557 37.6906 18.4904C38.0178 18.6251 38.3114 18.832 38.55 19.0961C39.024 19.7027 39.2628 20.4646 39.2212 21.2377ZM34.7581 21.2377C34.708 21.8679 34.8811 22.4955 35.2463 23.0076C35.4303 23.1968 35.6495 23.347 35.8912 23.4495C36.1328 23.552 36.3922 23.6048 36.6541 23.6048C36.916 23.6048 37.1753 23.552 37.4169 23.4495C37.6586 23.347 37.8778 23.1968 38.0619 23.0076C38.4128 22.4893 38.5788 21.8649 38.5326 21.2377C38.5817 20.6102 38.4154 19.9849 38.0619 19.4678C37.8898 19.2646 37.6732 19.1052 37.4295 19.0022C37.1858 18.8993 36.9217 18.8556 36.6584 18.8749C36.3941 18.8583 36.1296 18.9031 35.8849 19.0059C35.6403 19.1086 35.4218 19.2666 35.2463 19.4678C34.8811 19.9799 34.708 20.6075 34.7581 21.2377Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M43.3447 24.1124L41.4705 19.0681C41.4705 19.4664 41.5315 19.9531 41.5315 20.4929V24.1124H40.9301V18.4221H41.9064L43.6498 23.1124L45.3932 18.4221H46.3695V24.1124H45.7157V20.4487C45.7157 20.0239 45.7157 19.5637 45.768 19.077L43.8503 24.1124H43.3447Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M51.9308 20.077C51.9435 20.3293 51.8972 20.5811 51.7957 20.8118C51.6942 21.0426 51.5404 21.2456 51.3468 21.4044C50.8602 21.7478 50.2731 21.9131 49.6818 21.8734H49.028V24.1124H48.3743V18.4221H49.83C51.1899 18.4221 51.9308 18.9796 51.9308 20.077ZM49.028 21.3071H49.6121C50.0488 21.3398 50.4854 21.2413 50.8673 21.0239C50.9952 20.9173 51.0965 20.7816 51.1631 20.6279C51.2297 20.4741 51.2598 20.3066 51.2509 20.1389C51.2628 19.979 51.2356 19.8186 51.1717 19.6719C51.1079 19.5253 51.0093 19.3969 50.8848 19.2982C50.5453 19.0869 50.1484 18.9908 49.7516 19.0239H49.028V21.3071Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M53.4565 24.1124V18.4221H54.1103V23.5106H56.5773V24.1124H53.4565Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M57.9199 24.1124V18.4221H58.5737V24.1124H57.9199Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M64.0217 24.1135L63.3243 22.2993H61.0753L60.3867 24.1135H59.7765L61.9906 18.4055H62.5398L64.7365 24.1135H64.0217ZM63.15 21.7064L62.4962 19.9365C62.3937 19.6674 62.3064 19.3926 62.2347 19.1135C62.1702 19.3927 62.0887 19.6676 61.9906 19.9365L61.3368 21.7064H63.15Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M70.2368 24.1124H69.4959L66.4362 19.3336C66.4362 19.9 66.4972 20.4133 66.4972 20.8734V24.1124H65.8783V18.4221H66.6105L69.6615 23.1743C69.6615 23.1035 69.6615 22.8823 69.6615 22.5018C69.6615 22.1212 69.6615 21.8469 69.6615 21.6876V18.4221H70.2368V24.1124Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M73.8888 24.1124H73.235V19.015H71.4916V18.4221H75.6845V19.015H73.9411L73.8888 24.1124Z" fill="#33498D" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M23.9671 0.361014C23.7575 0.137214 23.4691 0.00676223 23.1651 -0.00181737H1.69493C1.53264 -0.00720473 1.37106 0.0223401 1.22082 0.084875C1.07058 0.14741 0.93508 0.241519 0.823225 0.361014C0.705518 0.47457 0.612818 0.612132 0.551219 0.764656C0.489621 0.91718 0.460518 1.08121 0.465825 1.24597V15.715C0.481055 16.8219 0.705977 17.9156 1.12832 18.9362C1.5101 19.9486 2.02943 20.9018 2.67124 21.7681C3.32239 22.6402 4.05513 23.4463 4.85923 24.1752C5.60091 24.8758 6.38714 25.5261 7.21284 26.1221C7.92764 26.6442 8.67731 27.1309 9.46184 27.5911C10.2464 28.0513 10.7956 28.361 11.1268 28.4761C11.4581 28.5911 11.7196 28.7681 11.9201 28.8566C12.0693 28.931 12.2332 28.9703 12.3995 28.9716C12.5685 28.9693 12.735 28.9301 12.8877 28.8566C13.0881 28.7681 13.3497 28.6442 13.6722 28.4761C13.9947 28.3079 14.5439 27.9982 15.3372 27.5911C16.1304 27.184 16.8714 26.6442 17.5949 26.1221C18.4172 25.5217 19.2032 24.8716 19.9485 24.1752C20.7511 23.4421 21.4864 22.6364 22.1452 21.7681C22.7806 20.8976 23.2994 19.9453 23.6881 18.9362C24.0957 17.9126 24.3058 16.8191 24.307 15.715V1.21057C24.314 1.0464 24.2865 0.882602 24.2264 0.730033C24.1663 0.577464 24.0749 0.439611 23.9583 0.325616L23.9671 0.361014Z" fill="url(#paint0_linear_1_3857)" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
        <path d="M21.0897 7.96238L19.5642 6.42256C19.4646 6.32141 19.3464 6.24118 19.2163 6.18643C19.0861 6.13169 18.9466 6.10352 18.8058 6.10352C18.6649 6.10352 18.5255 6.13169 18.3953 6.18643C18.2652 6.24118 18.147 6.32141 18.0474 6.42256L10.7163 13.8827L7.4736 10.5288C7.27101 10.3239 6.99676 10.2089 6.71085 10.2089C6.42495 10.2089 6.15069 10.3239 5.94811 10.5288L4.38775 12.0774C4.28812 12.1785 4.20909 12.2985 4.15516 12.4306C4.10124 12.5627 4.07349 12.7043 4.07349 12.8473C4.07349 12.9903 4.10124 13.1319 4.15516 13.264C4.20909 13.3961 4.28812 13.5162 4.38775 13.6172L8.43247 17.6969L9.94924 19.2456C10.155 19.4441 10.4281 19.5549 10.712 19.5549C10.9959 19.5549 11.269 19.4441 11.4747 19.2456L12.9915 17.6969L21.0897 9.4845C21.1912 9.38951 21.2729 9.27474 21.3298 9.14702C21.3867 9.01931 21.4177 8.88128 21.4209 8.74114C21.4214 8.59519 21.3922 8.45072 21.3352 8.31673C21.2782 8.18274 21.1946 8.06211 21.0897 7.96238Z" fill="white" data-sentry-element="path" data-sentry-source-file="HIPAALogo.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="HIPAALogo.tsx">
        <linearGradient id="paint0_linear_1_3857" x1="0.500693" y1="14.5026" x2="24.3157" y2="14.5026" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="HIPAALogo.tsx">
          <stop stopColor="#31498D" data-sentry-element="stop" data-sentry-source-file="HIPAALogo.tsx" />
          <stop offset="0.17" stopColor="#395A98" data-sentry-element="stop" data-sentry-source-file="HIPAALogo.tsx" />
          <stop offset="0.5" stopColor="#4E85B5" data-sentry-element="stop" data-sentry-source-file="HIPAALogo.tsx" />
          <stop offset="0.96" stopColor="#70CAE3" data-sentry-element="stop" data-sentry-source-file="HIPAALogo.tsx" />
          <stop offset="0.97" stopColor="#71CCE4" data-sentry-element="stop" data-sentry-source-file="HIPAALogo.tsx" />
        </linearGradient>
        <clipPath id="clip0_1_3857" data-sentry-element="clipPath" data-sentry-source-file="HIPAALogo.tsx">
          <rect width="92" height="31" fill="white" transform="translate(0.5)" data-sentry-element="rect" data-sentry-source-file="HIPAALogo.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default HIPAALogo;